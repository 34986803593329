<template>
  <!--
    Used to get information about the devices from the system.
  -->
  <Portlet
    :title="$t('deviceReportComponent.deviceReport')"
    class="deviceReport"
    icon="hdd"
  >
    <!-- LoadingPlaceholder -->
    <LoadingPlaceholder v-if="loadingDiscoveredDeviceTypes || loadingInstallationTypes" />

    <template v-else>
      <!-- Search -->
      <div
        v-if="discoveredDeviceTypes != null"
        class="row"
      >
        <div class="col-12 col-sm-8 col-xl-3 mb-3">
          <div class="input-group">
            <div class="input-prepend">
              <label class="mr-3 mt-2 mb-0">{{ $t('type') }}</label>
            </div>
            <select
              v-model="filter.discoveredDeviceType"
              class="form-control rounded cursor-pointer"
            >
              <option :value="null">
                {{ $t('pleaseSelectOne') }}
              </option>
              <option
                v-for="(type, typeIndex) in discoveredDeviceTypes"
                :key="`localDeviceManagementByInstallationDeviceTypeSelectOption-${ typeIndex }`"
                :value="type"
              >
                {{ type }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-4 col-xl-2 mb-3">
          <button
            v-if="filter.discoveredDeviceType != null"
            class="btn btn-primary btn-block"
            @click="getSkimovieInstallations()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="search"
            />
            <span>{{ $t('search') }}</span>
          </button>
          <button
            v-else
            class="btn btn-primary cursor-not-allowed btn-block"
            disabled
          >
            <font-awesome-icon
              class="mr-2"
              icon="search"
            />
            <span>{{ $t('search') }}</span>
          </button>
        </div>
      </div>

      <Portlet
        v-if="displayProgress || devicesAreAvailable || errorArrayIsAvailable"
        title="Report"
        icon="list-ul"
      >
        <template slot="buttons">
          <!-- Progress display -->
          <span class="mr-2">{{ $t('progress') }}:</span>
          <span
            v-if="displayProgress"
            class="progress"
          >
            <div
              v-if="computedProgress !== 0"
              :style="`width: ${ computedProgress }%;`"
              :aria-valuenow="computedProgress"
              class="progress-bar bg-success"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            >{{ computedProgress }} %</div>
            <span v-else>{{ computedProgress }} %</span>
          </span>
        </template>

        <template v-if="devicesAreAvailable || errorArrayIsAvailable">
          <!-- Filter -->
          <div
            v-if="deviceModelsAreAvailable"
            class="float-right mb-3"
          >
            <div class="input-group">
              <div class="input-prepend">
                <label class="mr-3 mt-2 mb-0">{{ $t('filter') }}</label>
              </div>
              <select
                v-model="filter.deviceModel"
                class="form-control rounded cursor-pointer"
              >
                <option :value="null">
                  {{ $t('pleaseSelectOne') }}
                </option>
                <option
                  v-for="(model, modelIndex) in getDeviceModelArray"
                  :key="`localDeviceManagementByInstallationDeviceModelSelectOption-${ modelIndex }`"
                  :value="model"
                >
                  {{ model }}
                </option>
              </select>
            </div>
          </div>
          <div class="clearfix" />

          <!-- Result -->
          <div
            v-if="devicesAreAvailable"
            class="row"
          >
            <div class="col-12">
              <table class="defaultTable">
                <thead>
                  <tr>
                    <th>{{ $t('installation') }}</th>
                    <th>{{ $t('manufacturer') }}</th>
                    <th>{{ $t('model') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(device, deviceIndex) in devices">
                    <tr
                      v-if="isInSelection(device)"
                      :key="`localDeviceManagementByInstallationTableRow-${ deviceIndex }`"
                    >
                      <td>{{ device.installationName }}</td>
                      <td>{{ device.manufacturer }}</td>
                      <td>{{ device.model }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Errors -->
          <div
            v-if="errorArrayIsAvailable"
            class="row mt-3"
          >
            <div class="col-12">
              <h4 class="m-0 p-0">
                {{ $t('errors') }}
              </h4>
              <hr class="m-0 p-0 mb-3 mt-1">
              <table class="defaultTable">
                <thead>
                  <tr>
                    <th>{{ $t('installation') }}</th>
                    <th>{{ $t('error') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(error, errorIndex) in errorArray"
                    :key="`localDeviceManagementByInstallationErrorTableRow-${ errorIndex }`"
                  >
                    <td class="red">
                      {{ error.installationName }}
                    </td>
                    <td class="red">
                      {{ error.errorMessage ? error.errorMessage : $t('deviceReportComponent.devicesNotAvailable') }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>

        <LoadingPlaceholder v-else-if="progressDone == false" />

        <p v-else-if="progressDone == true">
          {{ $t('noDataAvailable') }}
        </p>
      </Portlet>
    </template>
  </Portlet>
</template>

<script>
export default {
  name: 'DeviceReport',
  data () {
    return {
      installations: null,
      devices: null,
      discoveredDeviceTypes: null,
      progress: {
        current: 0,
        done: null
      },
      filter: {
        deviceModel: null,
        discoveredDeviceType: null
      },
      installationTypeId: null,
      loadingDiscoveredDeviceTypes: true,
      loadingInstallationTypes: true,
      requestIndex: false,
      registeredIpAddresses: null,
      errorArray: null,
      cancelSkimovieInstallationsSource: null,
      cancelCheckInstallationsForAvailableDevicemanagementSource: null,
      cancelGetInstallationDevicesSource: null,
      cancelGetInstallationTypesSource: null,
      cancelGetDiscoveredDeviceTypesSource: null
    }
  },
  computed: {
    computedProgress () {
      return ((this.progress.current / this.progress.done) * 100).toFixed(0);
    },
    getDeviceModelArray () {
      let deviceModels = [];
      this.devices.forEach(device => {
        if (device == null) {
          return;
        }
        if (device.model == null) {
          return;
        }
        if (device.model.length == 0) {
          return;
        }
        if (deviceModels.includes(device.model) == false) {
          deviceModels.push(device.model);
        }
      });
      deviceModels.sort();
      return deviceModels;
    },
    devicesAreAvailable () {
      if (this.devices === null) {
        return false;
      }
      if (this.devices.length == 0) {
        return false;
      }
      return true;
    },
    deviceModelsAreAvailable () {
      if (this.devicesAreAvailable == false) {
        return false;
      }
      if (this.getDeviceModelArray.length <= 1) {
        return false;
      }
      return true;
    },
    errorArrayIsAvailable () {
      if (this.errorArray === null) {
        return false;
      }
      if (this.errorArray.length == 0) {
        return false;
      }
      return true;
    },
    displayProgress () {
      if (this.progress.done == null) {
        return false;
      }
      return true;
    },
    progressDone () {
      if (this.progress.done == this.progress.current) {
        return true;
      }
      return false;
    }
  },
  created () {
    this.requestIndex = 0;
    this.getDiscoveredDeviceTypes();
    this.getInstallationTypes();
  },
  beforeDestroy () {
    this.requestIndex++;
    this.cancelSkimovieInstallationsRequest();
    this.cancelCheckInstallationsForAvailableDevicemanagementRequest();
    this.cancelGetInstallationDevicesRequest();
    this.cancelGetInstallationTypesRequest();
    this.cancelGetDiscoveredDeviceTypesRequest();
  },
  methods: {

    isInSelection (device) {
      if (device.model == null) {
        return true;
      }
      if (this.filter.deviceModel == null) {
        return true;
      }
      if (device.model.toLowerCase() === this.filter.deviceModel.toLowerCase()) {
        return true;
      }
      return false;
    },

    getSkimovieInstallations () {      
      this.cancelSkimovieInstallationsRequest();
      this.cancelCheckInstallationsForAvailableDevicemanagementRequest();
      this.cancelGetInstallationDevicesRequest();
      this.cancelSkimovieInstallationsSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelSkimovieInstallationsSource.token };
      this.axios.get(`/Installation/GetByInstallationTypeIds?installationTypeIds=${ this.installationTypeId }`, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.installations = response.data;
          this.progress.current = 0;
          this.progress.done = this.installations.length;
          this.devices = [];
          this.errorArray = [];
          this.filter.deviceModel = null;
          this.requestIndex++;
          let currentIndex = Number(`${this.requestIndex}`);
          this.checkInstallationsForAvailableDevicemanagement(currentIndex);
        });
    },
    cancelSkimovieInstallationsRequest () {
      if (this.cancelSource) {
        this.cancelSkimovieInstallationsSource.cancel();
      }
      this.cancelSkimovieInstallationsSource = null;
    },

    checkInstallationsForAvailableDevicemanagement (currentIndex) {
      this.cancelCheckInstallationsForAvailableDevicemanagementRequest();
      this.cancelCheckInstallationsForAvailableDevicemanagementSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelCheckInstallationsForAvailableDevicemanagementSource.token };
      this.axios.get(`/Installation/GetEnvironmentInformation/${ this.installations[this.progress.current].id }`, requestConfig)
        .then((result) => {
          if (result == null) {
            return;
          }
          if (result.data == null) {
            return;
          }
          if (result.data.showLocalDeviceManagement == null) {
            return;
          }
          this.installations[this.progress.current].showLocalDeviceManagement = result.data.showLocalDeviceManagement;
          
        })
        .finally(() => {
          if (this.installations[this.progress.current].showLocalDeviceManagement == true) {
            this.getInstallationDevices(this.installations[this.progress.current]);
          }
          this.progress.current++;
          if (this.progress.current == this.progress.done) {
            this.installations = null;
            return;
          }
          if (this.requestIndex == currentIndex) {
            this.checkInstallationsForAvailableDevicemanagement(currentIndex);
          }
        });
    },
    cancelCheckInstallationsForAvailableDevicemanagementRequest () {
      if (this.cancelSource) {
        this.cancelCheckInstallationsForAvailableDevicemanagementSource.cancel();
      }
      this.cancelCheckInstallationsForAvailableDevicemanagementSource = null;
    },

    getInstallationDevices (installation) {
      this.registeredIpAddresses = [];
      this.cancelGetInstallationDevicesRequest();
      this.cancelGetInstallationDevicesSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelGetInstallationDevicesSource.token };
      this.axios.get(`/DeviceManagement/GetDevices?installationId=${ installation.id }`, requestConfig)
        .then((response) => {
          let errorMessage = this.$t('deviceReportComponent.invalidResponse');
          if (response == null) {
            this.addErrorEntry(installation, errorMessage);
            return;
          }
          if (response.data == null) {
            this.addErrorEntry(installation, errorMessage);
            return;
          }
          if (response.status == null) {
            this.addErrorEntry(installation, errorMessage);
            return;
          }
          if (response.status != 200) {
            this.addErrorEntry(installation, errorMessage);
            return;
          }
          this.filterDevices(response.data, installation);
        });
    },
    cancelGetInstallationDevicesRequest () {
      if (this.cancelSource) {
        this.cancelGetInstallationDevicesSource.cancel();
      }
      this.cancelGetInstallationDevicesSource = null;
    },

    addErrorEntry (installation, errorMessage) {
      this.errorArray.push({
        installationId: installation.id,
        installationName: installation.name,
        errorMessage: errorMessage
      });
    },

    filterDevices (temporaryDevices, installation) {
      let filteredDevices = [];
      temporaryDevices.forEach(device => {
        if (this.registeredIpAddresses.includes(device.ipAddress) == true) {
          return;
        }
        if (device.discoveredDeviceType.toLowerCase() !== this.filter.discoveredDeviceType.toLowerCase()) {
          return;
        }
        this.registeredIpAddresses.push(device.ipAddress);
        device.installationId = installation.id;
        device.installationName = installation.name;
        filteredDevices.push(device);
      });
      if (filteredDevices.length == 0) {
        this.addErrorEntry(installation, this.$t('deviceReportComponent.noDeviceOfType'));
        return;
      }
      this.devices = this.devices.concat(filteredDevices);
    },

    getInstallationTypes () {
      this.cancelGetInstallationTypesRequest();
      this.cancelGetInstallationTypesSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelGetInstallationTypesSource.token };
      this.axios.get(`/Installation/GetInstallationTypes`, requestConfig)
        .then((response) => {
          let installationTypes = response.data;
          installationTypes.forEach(type => {
            if (type.name.toLowerCase().includes('skimovie')) {
              this.installationTypeId = type.id;
              return;
            }
          });
        })
        .finally(() => {
          this.loadingInstallationTypes = false;
        });
    },
    cancelGetInstallationTypesRequest () {
      if (this.cancelSource) {
        this.cancelGetInstallationTypesSource.cancel();
      }
      this.cancelGetInstallationTypesSource = null;
    },

    getDiscoveredDeviceTypes () {
      this.cancelGetDiscoveredDeviceTypesRequest();
      this.cancelGetDiscoveredDeviceTypesSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelGetDiscoveredDeviceTypesSource.token };
      this.axios.get(`/DeviceManagement/GetDiscoveredDeviceTypes`, requestConfig)
        .then((response) => {
          this.discoveredDeviceTypes = response.data;
        })
        .finally(() => {
          this.loadingDiscoveredDeviceTypes = false;
        });
    },
    cancelGetDiscoveredDeviceTypesRequest () {
      if (this.cancelSource) {
        this.cancelGetDiscoveredDeviceTypesSource.cancel();
      }
      this.cancelGetDiscoveredDeviceTypesSource = null;
    },

  }
}
</script>

<style scoped>
.progress {
  height: 30px;
  font-size: 1rem;
  line-height: 30px;
  position: relative;
  width: 100px;
}
.progress span {
  width: 100%;
  text-align: center;
  color: white;
}
</style>
